import { default as index3ddZ6RGu73Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/index.vue?macro=true";
import { default as packageslJTox3DyEmMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/packages.vue?macro=true";
import { default as parts6GlnpmAOsBMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/parts.vue?macro=true";
import { default as custom1FSCwN2yvIMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom.vue?macro=true";
import { default as indexKPDxHPu529Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/index.vue?macro=true";
import { default as ratesUsJWcyvVzcMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/rates.vue?macro=true";
import { default as subscriptionCLDpOtTqevMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/subscription.vue?macro=true";
import { default as accountyLwU90NIL1Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account.vue?macro=true";
import { default as _91id_93FzjGTM1xTdMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/edit/[id].vue?macro=true";
import { default as index58bvEjPWYXMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93PUSmkZCcYUMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_935tP5wyRzGfMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/commandes/[id].vue?macro=true";
import { default as index7pVOh9CmwMMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesX4HlHiM9NDMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/vehicules.vue?macro=true";
import { default as entretienDcBmmRP0dqMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/entretien.vue?macro=true";
import { default as indexIIMIVFgzd2Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/index.vue?macro=true";
import { default as outillagel8z2tw96wYMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/outillage.vue?macro=true";
import { default as indexfUvGOiX23rMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/index.vue?macro=true";
import { default as manualskY7iu5KogKMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/manuals.vue?macro=true";
import { default as timesmeU4aGmmm2Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/times.vue?macro=true";
import { default as catalogTKxO6y6IC1Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog.vue?macro=true";
import { default as commandeZCFU3Dy0dRMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/commande.vue?macro=true";
import { default as _91tab_93ssveNZVzSlMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/devis/[tab].vue?macro=true";
import { default as _91id_9311ONCWRcZMMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93rOLmuaiNVSMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfHdYPywVmu8Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/imprimer-pdf.vue?macro=true";
import { default as indexEDI8GMIGgrMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/index.vue?macro=true";
import { default as login0uvcYqAUlaMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/login.vue?macro=true";
import { default as panierOXDWy9aBKjMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/panier.vue?macro=true";
import { default as indexcTj4CkjQaRMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/index.vue?macro=true";
import { default as Liste5TAQjaCsMqMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/Liste.vue?macro=true";
import { default as listinglZTkgCcKVKMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/listing.vue?macro=true";
import { default as products6ewUKLcOsSMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/products.vue?macro=true";
import { default as tableau_45de_45bordU0iomUkx0BMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountyLwU90NIL1Meta?.name,
    path: "/account",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: custom1FSCwN2yvIMeta?.name,
    path: "custom",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom.vue").then(m => m.default || m),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/index.vue").then(m => m.default || m)
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/packages.vue").then(m => m.default || m)
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/parts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/rates.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/subscription.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/show/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/commandes/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/commandes/index.vue").then(m => m.default || m)
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/vehicules.vue").then(m => m.default || m)
  },
  {
    name: "Boutique-entretien",
    path: "/Boutique/entretien",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/entretien.vue").then(m => m.default || m)
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/index.vue").then(m => m.default || m)
  },
  {
    name: "Boutique-outillage",
    path: "/Boutique/outillage",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/outillage.vue").then(m => m.default || m)
  },
  {
    name: catalogTKxO6y6IC1Meta?.name,
    path: "/catalog",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/manuals.vue").then(m => m.default || m)
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/times.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/commande.vue").then(m => m.default || m)
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/devis/[tab].vue").then(m => m.default || m)
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/devis/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/devis/show/[id].vue").then(m => m.default || m)
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfHdYPywVmu8Meta || {},
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/imprimer-pdf.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login0uvcYqAUlaMeta || {},
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/panier.vue").then(m => m.default || m)
  },
  {
    name: "pneumatique",
    path: "/pneumatique",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/index.vue").then(m => m.default || m)
  },
  {
    name: "pneumatique-Liste",
    path: "/pneumatique/Liste",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/Liste.vue").then(m => m.default || m)
  },
  {
    name: "pneumatique-listing",
    path: "/pneumatique/listing",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/listing.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/tableau-de-bord.vue").then(m => m.default || m)
  }
]